import React, { useEffect } from 'react';
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
//import { API, Storage } from "aws-amplify";
import {
 Flex,
//  Heading,
  Image,
//  View,
} from "@aws-amplify/ui-react";
//import { listNotes } from "./graphql/queries";
import { Routes, Route, Link } from 'react-router-dom';
import Home from './component/home';
import About from './component/about';
import Tos from './component/tos';
import Help from './component/help';
import Ssllogo from "./th.jpg";
import Smblogo from "./Badge_3.png";
import background from "./b11b.png";
import logo from './logo.svg';


function App() {

	useEffect(() => {
		document.title = 'GSAlpha - On-Demand Cloud Gaming PCs';
	  }, []);


  return (<>


  
      

     
		  <div className = 'Body'   >


    
    <div className="App"  >
			
	
			
		
		<Routes>
				<Route exact path='/' element={< Home />}></Route>
				<Route exact path='/about' element={< About />}></Route>
				<Route path='/tos' element={< Tos />}></Route>
				<Route path='/help' element={< Help />}></Route>
				<Route path="*" element={<NotFound />}></Route>
		</Routes>
		
	

	
		</div>

		



		<div className='footBox' >
		

			<span>	<a className = "emailLink" style={{ textDecoration: 'none' }} href="mailto:support@gsalpha.com">support@gsalpha.com</a> <br></br>
		 	   </span>

				<div className='divBox'> <br></br></div>


				<Flex
	direction="row"
    justifyContent="center"
    alignItems="flex-start"
    textAlign="center"
	paddingLeft="0%" 
	gap= "2vw"
  >
<Image
			
			  src={Ssllogo} alt='' objectFit='cover'  height ="40px" width='auto' ></Image>
		<a href="https://aws.amazon.com/what-is-cloud-computing"><Image src="https://d0.awsstatic.com/logos/powered-by-aws.png" border='1px solid white' backgroundColor='white' objectFit='cover' height='40px' width='auto' alt="Powered by AWS Cloud Computing"></Image></a> <img src={logo} className="App-logo" alt="logo" />


		 <a href="https://www.paypal.com/webapps/mpp/paypal-popup" title="How PayPal Works" onclick="javascript:window.open('https://www.paypal.com/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;"><img src="https://www.paypalobjects.com/webstatic/mktg/logo/AM_mc_vs_dc_ae.jpg" border="0" alt="PayPal Acceptance Mark" width='120'></img></a>
		 
		

</Flex>


		 Copyright © 2023 GSAlpha Project - TPW Real Estate Services LLC - All Rights Reserved. 
		</div>
		
  
  
		
	

		
		</div>
	
      
		</>



  );
};

export const NotFound = () => {
	return <div>This is a 404 page</div>
  }

//export default withAuthenticator(App);
export default App;