import React from 'react';
//import logo from './s1iwy.JPG';
import {
 Flex,
  Heading,
Image,
//  View,
} from "@aws-amplify/ui-react";
import { Link } from 'react-router-dom';
//import background from "../background3.jpeg";
import background from "../b11c.png";
import backgroundA from "./a9a.png";
import backgroundB from "./a6a.png";
import backgroundC from "./a15a.png";






function Home (){


function goApp() {

	window.open("https://app.gsalpha.net");


};

 // if ((window.matchMedia("(orientation: portrait)").matches)) {var tmpgap = '1rem'} else {tmpgap = '0rem'};
 var tmpgap = '0rem';


	var tmpnum = Math.random();
	if( tmpnum < 0.33) {var tmpbg = '1' 
} else if ( tmpnum < 0.66 && tmpnum > 0.33) {
	tmpbg='2'
} else {
	 tmpbg = '3'
	};






	return (<>
	


	<div className='navBarBox' >    <span className="navBarTitle"><Link style={{ textDecoration: 'none', color: 'white' }} to="/">GSAlpha </Link></span>

<Link className='navBarLinkActive' style={{ textDecoration: 'none' }} to="/">  Home</Link>

<Link className='navBarLink' style={{ textDecoration: 'none' }} to="/about">About</Link>

</div>
<div className='pageContent'>

<div style={{ height: '20%', opacity: '1', backgroundImage: `url(${background})`, backgroundRepeat:"no-repeat",backgroundPosition:"center top",backgroundSize:"cover" ,backgroundAttachment: 'fixed'  }}>


	<br></br>
	<Heading level={2} color='white' >Welcome to Game Service Alpha</Heading>
	
	
	<span className='pageContentMed2'>On-Demand Cloud Gaming PCs</span>
	<br></br><br></br>
	<Flex
		direction="row"
		justifyContent="center"
		alignItems="center"
		textAlign="center"
		marginLeft='3vw'
		marginRight='3vw'
	>
	<span className='pageContentMed2'>BETA Phase - Request Access at <a  style={{ textDecoration: 'none', color: '#fcef42' }} href="mailto:support@gsalpha.com">support@gsalpha.com</a> </span>
	</Flex>
	<br></br>
	</div>
	<br></br>
	<Heading level={2}>

	
		<button className = "App-link-button" style={{ marginTop: "5px" , paddingTop: "0px", paddingBottom: "8px",  fontWeight: "", display: "inline", fontSize: "38px", height: "auto", width: "auto" }} onClick={goApp}>Login Here</button>
	
      
	</Heading>
	
	
	


	
		
		<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'		
			color='black'	
			marginTop='15px'
		>
		<span>CLOUD GAMING PC OPTIONS:</span>
		<br></br>
		</Flex>
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='16px'
		>
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="left"
			textAlign="center"
			gap="calc(38px - 6vw)"
			marginLeft='0px'
			marginRight='0px'
			wrap='wrap'
			fontSize='18px'
		//	boxShadow='inset 0 0 0 1px grey'
	//		paddingRight='6vw'
	//		paddingLeft='6vw'
			paddingTop='4px'
			//paddingBottom='4px'
			backgroundColor='dark grey'
			color='black'
		>
		<span>System1 - 4x 2.8Ghz vCPU, 16GB RAM, Radeon Pro V520-8GB, HDD Storage - $0.65/hr</span>
		<span>System2 - 8x 2.8Ghz vCPU, 32GB RAM, Radeon Pro V520-8GB, SSD Storage - $1.15/hr</span>
		
		
		<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='14px'
			//paddingBottom='4px'
		>
		<span>*Billed in 1 min increments while Cloud PC is running</span>
		</Flex>

		</Flex>

		</Flex>











		<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'		
			color='black'	
			marginTop='15px'
		>
	<span>PERSISTANT STORAGE OPTIONS:</span>	
		<br></br>
		</Flex>
		<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'
		>
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="left"
			textAlign="left"
			//gap="calc(35px - 6vw)"
			gap='1px'
			//marginLeft='20px'
			//marginRight='20px'
			wrap='wrap'
			fontSize='18px'
		//	boxShadow='inset 0 0 0 1px grey'
	//		paddingRight='6vw'
		//	paddingLeft='6vw'
		//	paddingTop='4px'
		//	paddingBottom='4px'
			backgroundColor='dark grey'
			color='black'
		>
				
				<Flex 
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			fontSize='18px'
			gap="3vw"
		>
		
		<span>50GB	-	$3.00/mo<br></br>
		100GB	-	$5.75/mo<br></br>
		150GB	-	$8.50/mo</span>
		
		
		<span>200GB	-	$11.25/mo<br></br>
		250GB	-	$14.00/mo<br></br>
		300GB	-	$16.75/mo</span>
		
		</Flex>

	
		</Flex>
		</Flex>












		<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'		
			color='black'	
			marginTop='15px'
		>
	<span>DATACENTER REGIONS:</span>	
		<br></br>
		</Flex>
		<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='23px'
		>
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="left"
			textAlign="left"
			//gap="calc(35px - 6vw)"
			gap='1px'
			//marginLeft='20px'
			//marginRight='20px'
			wrap='wrap'
			fontSize='18px'
		//	boxShadow='inset 0 0 0 1px grey'
	//		paddingRight='6vw'
		//	paddingLeft='6vw'
		//	paddingTop='4px'
		//	paddingBottom='4px'
			backgroundColor='dark grey'
			color='black'
		>
				
				<Flex 
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			fontSize='18px'
			gap="3vw"
		>
		<span>US West</span>
		<span>US East</span>

		</Flex>
		</Flex>
		</Flex>


<br></br>
		
		<Flex 
			direction="column"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			marginTop="15px"
			marginBottom="15px"
		>


		
	{tmpbg === '1' ? (<><Image
        src={backgroundB}
        style={{ width: '500px' }}
      />
	  </>) : (
	  
		tmpbg === '2' ? (<>
		
		
			<Image
        src={backgroundA}
        style={{ width: '500px' }}
      />
		
		</>) : (<>
		
		
		
			<Image
        src={backgroundC}
        style={{ width: '500px' }}
      />
		
		
		</>)



	  )}






</Flex>



<br></br>

	</div>

	  </>
		
	
	)
}

export default Home;
