import React from 'react';
//import logo from './s1iwy.JPG';
import {
  Flex,
  Heading,
  Image,
//  View,
} from "@aws-amplify/ui-react";
import { Link } from 'react-router-dom';
import background from "../b11c.png";
import background2 from "../b12.jfif";
import background3 from "../b13.jfif";
import background4 from "../b14.jfif";
import background5 from "../b15.jfif";
import background6 from "../b16.jfif";
import background7 from "../b17.jfif";




function Home (){




	return (<>
	

	<div className='navBarBox' >    <span className="navBarTitle"><Link style={{ textDecoration: 'none', color: 'white' }} to="/">GSAlpha </Link></span>

<Link className='navBarLink' style={{ textDecoration: 'none' }} to="/">  Home</Link>

<Link className='navBarLinkActive' style={{ textDecoration: 'none' }} to="/about">About</Link>

</div>
<div className='pageContent' >
<div style={{ backgroundColor: "black" , height: '20%', opacity: '1', backgroundImage: `url(${background})`, backgroundRepeat:"no-repeat",backgroundPosition:"center top",backgroundSize:"cover" ,backgroundAttachment: 'fixed'  }}>
<br></br>
<Heading level={2} color="white" >Game Service Alpha</Heading>
<Flex
		direction="row"
		justifyContent="center"
		alignItems="center"
		textAlign="center"
		marginLeft='3vw'
		marginRight='3vw'
    
    >
<span className='pageContentMed2'>On-Demand Cloud Gaming PCs</span>
</Flex>
<br></br>
</div>
	



<Flex
 direction="column"
 justifyContent="center"
 alignItems="center"
 textAlign="center"
>
	
	<div style={{ textAlign: 'left', marginLeft: 'calc(6vw - 10px)',marginRight: 'calc(6vw - 10px)' }}>
<br></br>


	<span className='pageContentMed'>OUR OFFERINGS</span><br></br>
  <Flex
  direction="row"
  fontSize="16px"
  marginLeft='0px'
  marginRight='0px'
  >
	<Flex
  direction="column"
  marginLeft='10px'
  marginRight='10px'
  wrap='wrap'
  gap="calc(38px - 6vw)"
  >
		<span><span style={{textDecoration: 'underline' }}>System1</span> - 4 vCPU, 16GB RAM Cloud PC with HDD storage (max 150GB): cost-effective gaming system that can handle the majority of games out there.</span>
		<span><span style={{textDecoration: 'underline'}}>System2</span> - 8 vCPU, 32GB RAM Cloud PC with SSD storage (max 300GB): for the latest and most demanding games.</span>
		<span> *Both systems come with the AMD Radeon Pro V520 8GB GPU (3D-Mark TimeSpy Score: 7450) and Windows Server 2019</span>
    </Flex>
    </Flex>



<br></br>
<span className='pageContentMed'>CLOUD GAMING BENEFITS</span><br></br>
<Flex
direction="column"
  marginLeft='10px'
  marginRight='10px'
  wrap='wrap'
  gap="calc(38px - 6vw)"
  >
<span>1) <span style={{textDecoration: 'underline' }}>Game from Anywhere</span>: Access your cloud PC from almost any device with an internet connection, even on the go.</span>
<span>2) <span style={{textDecoration: 'underline' }}>Cost Savings</span>: Players can avoid the cost of purchasing expensive hardware, by using a cloud PC for a fraction of the cost.</span>
<span>3) <span style={{textDecoration: 'underline' }}>Constantly Improving</span>: The cloud PC is updated regularly with the latest technology, ensuring players have access to the latest games and graphics.</span>
<span>4) <span style={{textDecoration: 'underline' }}>Built-in Hardware Support</span>: No need for technical knowledge or maintenance, the cloud PC is maintained by GSAlpha and backed by AWS.</span>
</Flex>
<br></br>

<span className='pageContentMed'>CLOUD PC HARDWARE</span><br></br>
<Flex
  direction="column"
  marginLeft='10px'
  marginRight='10px'
  wrap='wrap'
  gap='0rem'
>
<span>CPU: 4x or 8x AMD EPYC 7R32 @2.8GHz	</span>
<span>GPU: AMD Radeon Pro V520 8GB</span>
<span>RAM: 16GB or 32GB DDR4</span>
<br></br>
</Flex>



</div>


</Flex>


</div>

<Flex
	direction="column"
  justifyContent="center"
  alignItems="center"
  textAlign="center"
  marginTop="30px"
  marginBottom="30px"
>
<Flex
	direction="row"
    justifyContent="center"
    alignItems="center"
    textAlign="center"
	paddingLeft="0%" 
  
  >
<Image
        src={background7}
        style={{ width: 125 }}
      />


<Image
        src={background2}
        style={{ width: 125 }}
      />

<Image
        src={background3}
        style={{ width: 125 }}
      />

<Image
        src={background4}
        style={{ width: 125 }}
      />

<Image
        src={background5}
        style={{ width: 125 }}
      />

<Image
        src={background6}
        style={{ width: 125 }}
      />
</Flex>
</Flex>

<br></br>


	  </>
		
	
	)
}

export default Home;
